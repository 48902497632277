import React, {useEffect, useState} from "react";
import Home from "../layouts/Home";
import {Backdrop, Box, Button, CircularProgress, Snackbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import ActivityCard from "../components/Agenda/ActivityCard";
import axios from "axios";
import authHeader from "../services/auth-header";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const agenda = [
    {
        day: 'Wednesday 15 June',
        activities: [
            {
                id: 'preview',
                timing: '5:00 - 7:15 PM CET',
                event: 'Private Preview',
                location: 'Centro Stile'
            },
            {
                id: 'gala',
                timing: '8:30 - 11:30 PM CET',
                event: 'Welcome Cocktail & Gala Dinner',
                location: 'Fiorano Track'
            }
        ]
    },
    {
        day: 'Thursday 16 June',
        activities: [
            {
                id: 'coffee',
                timing: '9:00 - 10:00 AM CET',
                event: 'Welcome Coffee',
                location: ''
            },
            {
                id: 'cmd',
                timing: '10:00 - 13:00 AM CET',
                event: 'Capital Markets Day',
                location: 'Logistic'
            },
            {
                id: 'lunch',
                timing: '1:00  - 2:30 PM CET',
                event: 'Lunch',
                location: ''
            },
            {
                id: 'tour',
                timing: 'from 2:30 PM CET',
                event: 'Plant Tour (Optional)',
                location: ''
            }
        ]
    }
]

export default function Agenda() {
    const [selected, setSelected] = useState({
        preview: '',
        gala: '',
        cmd: true,
        coffee: true,
        lunch: '',
        tour: ''
    })
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        compileData().then()
    }, [])

    const compileData = async () => {
        setLoading(true)
        await axios.get('https://cmd2022.ferrari.com/api/user/agenda', {headers: authHeader()})
            .then(r => {
                if (r.data !== '') {
                    setSelected(r.data)
                }
            })
        setLoading(false)
    }

    const handlePartecipate = (event, id) => {
        setSelected({...selected, [id]: event.target.value})
    }

    const handleLunch = (event) => {
        setSelected({...selected, lunch: event.target.value})
    }

    const handlePreview = (value) => {
        setSelected({...selected, preview: value})
    }

    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const [message,setMessage]=useState('')

    const handleSendData = async () => {
        setLoading(true)
        await postData()
        setLoading(false)
    }

    const postData = async () => {
        await axios.post('https://cmd2022.ferrari.com/api/user/updateAgenda', {data: selected}, {headers: authHeader()})
            .then(r => setSent(true))
            .catch(e => {
                if(e.response.status === 409) {
                    setMessage(e.response.data.error)
                }else {
                    setMessage('An error occurred, please try again')
                }

                setError(true)
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSent(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const handleCloseLoading = () => {
        setLoading(false)
    }

    return (
        <Home>
            <Box sx={{mb:10}}>
                <Snackbar open={sent} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Data successfully sent
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Typography variant={matches ? 'h3' : 'h5'}>Agenda</Typography>
                <Typography variant={matches ? 'h5' : 'subtitle1'} sx={{mt: 1}}>Please choose the activities you want to
                    attend</Typography>
                {
                    agenda.map(day =>
                        <Box sx={{mt: 3}} key={day.day}>
                            <Typography variant={"h4"}>{day.day}</Typography>
                            <Box style={{borderTop: '1px #ED1C24 solid', borderBottom: '1px #ED1C24 solid'}}>
                                {day.activities.map(activity =>
                                    <ActivityCard key={activity.id} activity={activity} selected={selected}
                                                  handlePartecipate={handlePartecipate} handleLunch={handleLunch}
                                                  handlePreview={handlePreview}
                                    />
                                )}
                            </Box>
                        </Box>
                    )
                }
                <Box style={{textAlign: 'right'}} sx={{my: 3}}>
                    <Button disabled={selected.gala === '' || selected.lunch === '' || selected.tour ==='' || selected.preview ===''} variant={"contained"} onClick={handleSendData}>
                        Submit
                    </Button>
                </Box>
            </Box>

        </Home>
    );
}
