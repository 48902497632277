import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Link, useNavigate} from "react-router-dom";
import logo from '../assets/images/cavallino.png'
import {Logout} from "@mui/icons-material";
import AuthService from "../services/auth.service";
import Dialog from "@mui/material/Dialog";
import {useState} from "react";
import {DialogActions, DialogContent, DialogContentText, Slide, Stack} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

const pages = [
    {title: 'Agenda', link: '/agenda'},
    {title: 'Accommodation', link: '/accommodation'},
    {title: 'Transfer', link: '/transfer'},
    //{title: 'Private preview', link: '/private-preview'},
    //{title: 'Leisure activity', link: '/leisure-activity'},
    {title: 'Catering', link: '/catering'},
    //{title: 'Content', link: '/content'},
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [open, setOpen] =useState(false)
    const navigate= useNavigate()

    const logOut = () => {
        AuthService.logout();
        navigate('/')
        window.location.reload()
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <AppBar position="sticky">
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to log out?"}</DialogTitle>

                <DialogActions>
                    <Button onClick={logOut}>Yes</Button>
                    <Button onClick={handleClose}>no</Button>
                </DialogActions>
            </Dialog>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box
                        component="div"
                        sx={{mr: 2, display: {xs: 'none', lg: 'flex'}}}
                    >
                        <img src={logo} style={{width: '100%', maxWidth: '50px', padding: '0.4rem'}}/>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem component={Link} to={page.link} key={page.title} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.title}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem component={Link} to={'/contacts'} onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">Contacts</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        component="div"
                        sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}
                    >
                        <img src={logo} style={{width: '100%', maxWidth: '30px'}}/>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', lg: 'flex'}}}>
                        {pages.map((page) => (
                            <Button
                                component={Link}
                                to={page.link}
                                key={page.title}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, mx: 0.8, color: 'white', display: 'block'}}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>

                    <Stack direction={"row"} sx={{flexGrow: 0}}>
                        <Button
                            component={Link}
                            to={'/contacts'}
                            sx={{mx: 0.8, color: 'white', display: {xs: 'none', lg:'block'}}}
                        >
                            Contacts
                        </Button>
                        <Box sx={{px:5}}/>
                        <IconButton onClick={() => setOpen(true)} sx={{p: 0}}>
                            <Logout/>
                        </IconButton>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Header;
