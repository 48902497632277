import React from "react";
import Home from "../layouts/Home";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";

const Contacts = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return(
      <Home>
          <Box sx={{mb:10}}>
              <Typography variant={matches ? 'h3' : 'h5'}>Contacts</Typography>
              <Typography variant={matches ? 'h5' : 'subtitle1'} sx={{mt: 1}}>
                  For any additional inquiries or support please contact:<br/><a style={{color:"white", textDecoration:'underline'}} href={'mailto:info@cmd2022.ferrari.com'} target={'_blank'}>info@cmd2022.ferrari.com</a>
                  <br/>
                  <br/>
                  or by phone on:
                  <br/>
                  +39 3929135701
              </Typography>
          </Box>
      </Home>
  )
}

export default Contacts
