import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import HotelCardContent from "./HotelCardContent";

const HotelCard = ({hotel}) => {
  return(
      <Box sx={{m: 1}}>
          <Accordion>
              <AccordionSummary expandIcon={<ExpandMore/>}>
                  <Stack direction={"column"}>
                      <Typography variant={"h6"}>
                          {hotel.name}
                      </Typography>
                      <Typography>
                          {hotel.stars}*
                      </Typography>
                      <Stack direction={"row"}>
                          <Typography>
                              {hotel.city} - {hotel.distance} from Maranello ({hotel.time})
                          </Typography>
                      </Stack>
                  </Stack>
              </AccordionSummary>
              <AccordionDetails>
                  <HotelCardContent hotel={hotel}/>
              </AccordionDetails>
          </Accordion>
      </Box>
  )
}

export default HotelCard
