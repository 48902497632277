import React from "react";
import {FormControl, ListSubheader, Select, useMediaQuery, useTheme} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import hotel from '../../assets/data/hotel.json'

const HotelSelect = ({dato, handleChange, type}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return(
      <FormControl fullWidth sx={{mb:2}}>
        <Select
            labelId="date"
            id="date"
            value={type === 'arrival' ? dato.to : dato.from}
            onChange={(event) => handleChange(event, dato.id, type === 'arrival' ? 'to' : 'from')}
        >
            <MenuItem value={'cmd'}>Capital Markets Day venue</MenuItem>
            <ListSubheader>Or select your hotel</ListSubheader>
              {hotel.map(h =>
                      <MenuItem value={h.name} style={{fontSize: matches ? '1rem' : '0.8rem'}}>
                          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{h.name}</div>
                      </MenuItem>
              )}
        </Select>
      </FormControl>
  )
}

export default HotelSelect
