import React from "react";
import {Container} from "@mui/material";

const Footer = () => {
  return(
      <footer className={'footer-contact'} style={{color: "white",position:'fixed', bottom: 0, width:'100%'}}>
          <Container style={{display:'flex', justifyContent:'right'}} maxWidth={"xl"}>
              <p style={{marginTop:'10px', fontSize:'min(1rem, 3vw)'}}>For any additional inquiries or support please contact: <a style={{color:"white", textDecoration:'underline'}} href={'mailto:info@cmd2022.ferrari.com'} target={'_blank'}>info@cmd2022.ferrari.com</a></p>
          </Container>
      </footer>
  )
}

export default Footer
