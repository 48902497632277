import React from "react";
import {Container, TextField} from "@mui/material";

const RegisterForm = ({compileDati, dati}) => {
  return(
      <Container maxWidth={"sm"} style={{color:'white'}} sx={{py:3}}>
          {dati.map(d=>
            <div className={'formRow'} key={d.id}>
                <p style={{color:'white', textTransform:'uppercase', fontSize:'1.4rem', margin:0}}>
                    {d.title}
                </p>
                <TextField id={d.id} required type={d.type} value={d.value} onChange={(event) => compileDati(event, d.id)}/>
            </div>
          )}
      </Container>
  )
}

export default RegisterForm
