import React from "react";
import {Box, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";

const HotelCardContent = ({hotel}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return(
      <Box>
          <Typography variant={"h6"}>Rooms available</Typography>
          <Box style={{borderTop: '1px #ED1C24 solid', borderBottom: '1px #ED1C24 solid'}}>
              {hotel.rooms.map(r =>
                  <Stack direction={matches ? "row" : "column"} sx={{my: 1}} justifyContent={"space-between"}>
                      <Typography>
                          {r.type}
                      </Typography>
                      <Typography>
                          {r.cost}
                      </Typography>
                  </Stack>
              )}
          </Box>
          <Typography variant={"h6"} sx={{mt:2}}>Contacts</Typography>
          <Box style={{borderTop: '1px #ED1C24 solid', borderBottom: '1px #ED1C24 solid'}}>
              <Typography variant={matches ? "subtitle1" : "caption"} gutterBottom component="div" sx={{my:1}}>
                  Email for booking: <a style={{textDecoration:'underline', color:'white'}} target={'_blank'} href={'mailto:'+hotel.booking.email}>{hotel.booking.email}</a>
              </Typography>
              <Typography variant={matches ? "subtitle1" : "caption"} gutterBottom component="div" sx={{my:1}}>
                  Use the code &nbsp; <b>{/*hotel.booking.code*/}Gruppo Ferrari</b> &nbsp; when booking
              </Typography>
              <Typography variant={matches ? "subtitle1" : "caption"} gutterBottom component="div" sx={{my:1}}>
                  Website for reference: <a style={{textDecoration:'underline', color:'white'}} href={hotel.website} target={'_blank'}>{hotel.name}</a>
              </Typography>
          </Box>
          {/*hotel.note !== '' && <Box sx={{mt: 2}}>
              <Typography variant={'caption'}>Note: {hotel.note}</Typography>
          </Box>*/}
      </Box>
  )
}

export default HotelCardContent
