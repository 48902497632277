import React, {useState} from "react";
import LogoHeader from "../components/LogoHeader";
import {Alert, Backdrop, Button, CircularProgress, Container, FormControl, Snackbar} from "@mui/material";
import lineTop from '../assets/images/line-top.png'
import lineBottom from '../assets/images/line-bottom.png'
import RegisterForm from "../components/RegisterForm";
import PrivacyForm from "../components/PrivacyForm";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";

const Register = () => {
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [dati, setDati] = useState([
        {id: 'name', title: 'first name', value: '', type: 'text'},
        {id: 'surname', title: 'last name', value: '', type: 'text'},
        {id: 'company', title: 'company name', value: '', type: 'text'},
        {id: 'role', title: 'role', value: '', type: 'text'},
        {id: 'phone', title: 'phone', value: '', type: 'number'},
        {id: 'email', title: 'email address', value: '', type: 'email'},
    ])

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [privacy1, setPrivacy1] = useState(null)
    const [privacy2, setPrivacy2] = useState(null)

    const compileDati = (event, id) => {
        let newArr = dati.map(obj => {
            if (obj.id === id) {
                return {...obj, value: event.target.value};
            }
            return obj;
        });

        setDati(newArr)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const handleCloseLoading =() => {
        setLoading(false)
    }

    const handlePrivacy1 = (event) => {
        setPrivacy1(event.target.value)
    }

    const handlePrivacy2 = (event) => {
        setPrivacy2(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(false)
        if (privacy1 === 'false' || privacy2 === 'false' || privacy2 === null || privacy1 === null) {
            setOpen(true)
        } else {
            setLoading(true)
            await sendData()
        }
        setLoading(false)
    }

    async function sendData() {
        await axios.post('https://cmd2022.ferrari.com/api/register', {data: dati})
            .then(r => {
                setSent(true)
            })
            .catch(r =>
                setError(true)
            )
    }

    return (
        <main>
            <Container sx={{py: {md: 3, xs: 0}}}>
                <LogoHeader/>
            </Container>
            <img src={lineTop} style={{width: '100%', maxWidth: '100%'}}/>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Please accept Privacy Terms
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Your email is already registered
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleCloseLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {!sent && <form onSubmit={handleSubmit}>
                <RegisterForm compileDati={compileDati} dati={dati}/>
                <img src={lineBottom} style={{width: '100%', maxWidth: '100%'}}/>
                <PrivacyForm handlePrivacy1={handlePrivacy1} handlePrivacy2={handlePrivacy2} privacy1={privacy1}
                             privacy2={privacy2}/>

                <div style={{textAlign: 'center'}}>
                    <Button type={"submit"} variant={"contained"}>
                        REGISTER
                    </Button>
                </div>
                <p style={{fontSize: '0.8rem', textAlign: 'center', fontWeight: '300'}}>
                    Kindly note that due to the limited capacity of the venue, Ferrari may not be able to accept all
                    participation requests. A confirmation email will follow depending on availability.
                </p>
            </form>}
            {sent && !loading &&
                <Container>
                    <p style={{fontSize: '1.4rem', textAlign: 'center', paddingTop:'3rem'}}>
                        Thanks for your interest.
                        <br/>
                        <br/>
                        Kindly note that due to the limited capacity of the venue, Ferrari may not be able to accept all participation requests.
                        <br/>
                        <br/>
                        A confirmation email will follow depending on availability.
                    </p>
                </Container>
            }
        </main>
    )
}

export default Register
