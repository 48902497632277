import React from "react";
import cavallino from '../assets/images/cavallino.png'

const LogoHeader = () => (
    <div className={'logoHeader'}>
        <img src={cavallino} style={{width: '100%', maxWidth: '6.563rem'}}/>
        <div>
            <p style={{textTransform:'uppercase', color:'#ED1C24', fontSize:'min(2rem,5.2vw)', fontWeight:'500', margin:0}}>
                capital markets day
            </p>
            <p style={{color:'white', fontSize:'min(1.7rem, 5vw)', margin:0}}>
                Maranello, June 15-16 2022
            </p>
        </div>

    </div>
)


export default LogoHeader
