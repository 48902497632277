import * as React from "react";
import {Routes, Route} from "react-router-dom";
import "./App.css";
import Agenda from "./pages/Agenda";
import {useEffect, useState} from "react";
import AuthService from "./services/auth.service";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {ferrariTheme} from "./theme/ferrariTheme";
import ProtectedRoute from "./components/ProtectedRoute";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Transfer from "./pages/Transfer";
import Catering from "./pages/Catering";
import Accommodation from "./pages/Accommodation";
import Contacts from "./pages/Contacts";


function App() {
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    }, []);

    return (
        <ThemeProvider theme={ferrariTheme}>
            <CssBaseline/>
            <Routes>
                <Route path={'/register'} element={<Register/>}/>
                <Route path={'/'} element={<Login/>}/>
                <Route element={<ProtectedRoute user={localStorage.user}/>}>
                    <Route path={'/agenda'} element={<Agenda/>}/>
                    <Route path={'/transfer'} element={<Transfer/>}/>
                    <Route path={'/catering'} element={<Catering/>}/>
                    <Route path={'/accommodation'} element={<Accommodation/>}/>
                    <Route path={'/contacts'} element={<Contacts/>}/>
                </Route>
            </Routes>

        </ThemeProvider>
    );
}

export default App;
