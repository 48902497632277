import React from 'react';
import Header from "../components/Header";
import {Container} from "@mui/material";
import Footer from "../components/Footer";

const Layout =({children}) =>{
    return(
        <>
            <Header/>
            <Container sx={{mt:2}}>{children}</Container>
            <Footer/>
        </>
    )
}

export default Layout;
