import React, {useState} from "react";
import {Alert, Backdrop, Button, CircularProgress, Container, Snackbar, TextField} from "@mui/material";
import LogoHeader from "../components/LogoHeader";
import lineTop from "../assets/images/line-top.png";
import lineBottom from "../assets/images/line-bottom.png";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import authService from "../services/auth.service";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";

const Login = () => {
    let navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const handleCloseLoading =() => {
        setLoading(false)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        authService.login(email, password).then(
            response => {
                    navigate("/agenda");
                    window.location.reload();
            },
            (error) => {
                setMessage('An error occurred, please try again')
                setLoading(false);
                setError(true);
            }
        )
    }

    return (
        <main>
            <Container sx={{py: {md: 3, xs: 0}}}>
                <LogoHeader/>
            </Container>
            <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleCloseLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <img src={lineTop} style={{width: '100%', maxWidth: '100%'}}/>
            <p style={{margin:'1rem', textAlign:'center', fontSize:'1.8rem'}}>
                Fill in your credentials to login
            </p>
            <Container maxWidth={"sm"} sx={{py:3}} style={{textAlign:'center'}}>
                <form onSubmit={handleLogin}>
                    <div className={'formRow'}>
                        <p style={{color:'white', textTransform:'uppercase', fontSize:'1.4rem', margin:0}}>
                            Email
                        </p>
                        <TextField id='email' required type='email' value={email} onChange={handleEmail}/>
                    </div>
                    <div className={'formRow'}>
                        <p style={{color:'white', textTransform:'uppercase', fontSize:'1.4rem', margin:0}}>
                            Password
                        </p>
                        <TextField id='password' required type='password' value={password} onChange={handlePassword}/>
                    </div>

                    <Button variant={"contained"} sx={{mt:3}} type={"submit"}>
                        Log In
                    </Button>
                </form>
            </Container>
            <img src={lineBottom} style={{width: '100%', maxWidth: '100%'}}/>
            <Footer/>
        </main>
    )
}

export default Login
