import React, {useEffect, useState} from "react";
import {Box, Card, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import ActivityCardContent from "./ActivityCardContent";

const ActivityCard = ({activity, selected, handlePartecipate, handleLunch, handlePreview}) => {
    const [sel, setSel] = useState(false)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (activity.id === 'lunch' || activity.id === 'preview' || activity.id==='gala' || activity.id==='tour') {
            if (selected[activity.id] !== '' && selected[activity.id] !== 'false') {
                setSel(true)
            } else {
                setSel(false)
            }
        } else {
            if (selected[activity.id]) {
                setSel(true)
            } else {
                setSel(false)
            }
        }
    }, [selected])

    return (
        <Card variant={'activity'} selected={(activity.id !=='cmd' && activity.id!=='coffee') ? sel : true} sx={{my: 4, ml: 0, mr: 4}}>
            <Stack sx={{px: 2, py: 2}} style={{height: '100%'}} direction={matches ? "row" : "column"}
                   justifyContent={"space-between"} alignItems={matches ? "center" : 'flex-start'}
                   alignContent={"center"}>
                <Box sx={{pb: {xs: 2, md: 0}}}>
                    <Typography variant={"h6"}>{activity.event}</Typography>
                    <Typography>
                        {activity.timing}
                    </Typography>
                </Box>

                {(activity.id !=='cmd' && activity.id !=='coffee') && <ActivityCardContent activity={activity} selected={selected[activity.id]}
                                      handlePartecipate={handlePartecipate} handleLunch={handleLunch}
                                      handlePreview={handlePreview}/>}
            </Stack>
        </Card>
    )
}

export default ActivityCard
