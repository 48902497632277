import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, Button, CircularProgress, Grid, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import authHeader from "../../services/auth-header";

const time = [
    {
        time:'15:00',
        available:true
    },
    {
        time:'15:15',
        available:false
    },
    {
        time:'15:30',
        available:true
    },
    {
        time:'15:45',
        available:true
    },
    {
        time:'16:00',
        available:true
    },
    {
        time:'16:15',
        available:true
    },
]


export default function SlotDialog({open, handleClose}) {
    const [timeSlot, setTimeSlot] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=> {
        setLoading(true)
        getSlot().then()
        setLoading(false)
    },[open])

    const getSlot = async () => {
        await axios.get('https://cmd2022.ferrari.com/api/slots', {headers:authHeader()})
            .then(response => setTimeSlot(response.data))
            .catch(handleClose)
    }

    return (
        <Dialog onClose={() => handleClose('')} open={open}>
            <DialogTitle>Choose your slot</DialogTitle>
            {!loading && <Grid container spacing={2} sx={{p: 2}}>
                {
                    timeSlot.map(time =>
                        <Grid key={time.time} item xs={12} md={6} lg={4}>
                            <Button onClick={() => handleClose(time.time)} style={{width: '100%'}} variant={"contained"}
                                    disabled={!time.available}>{time.time}</Button>
                        </Grid>
                    )
                }
            </Grid>}
            {(loading || timeSlot.length<=0) &&
                <Box style={{width:'100%', textAlign:'center'}}>
                    <CircularProgress sx={{my:3}}/>
                </Box>
            }

        </Dialog>
    );
}
