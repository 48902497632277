import React from "react";
import {Container, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const PrivacyForm = ({handlePrivacy1, handlePrivacy2, privacy2, privacy1}) => {
    return (
        <Container maxWidth={'xl'} style={{fontSize: '0.8rem', textAlign: 'center', fontWeight: '300'}}>
            <p>
                After having read and understood the <a href={'/privacy.pdf'} target={'_blank'} style={{textDecoration: 'underline', color: 'white'}}>Privacy
                Notice</a>
            </p>

            <RadioGroup
                row
                aria-labelledby="privacy-1"
                name="privacy-1"
                value={privacy1}
                onChange={handlePrivacy1}
                sx={{justifyContent: 'center'}}
            >
                <FormControlLabel value={'true'} control={<Radio/>} label="I consent"/>
                <FormControlLabel value={'false'} control={<Radio/>} label="I do not consent"/>
            </RadioGroup>

            <p>
                to my images being used and published as stated in point (iii) of the <a href={'/privacy.pdf'} target={'_blank'} style={{
                textDecoration: 'underline',
                color: 'white'
            }}>Privacy Notice</a>
            </p>

            <RadioGroup
                row
                aria-labelledby="privacy-2"
                name="privacy-2"
                value={privacy2}
                onChange={handlePrivacy2}
                sx={{justifyContent: 'center'}}
            >
                <FormControlLabel value={true} control={<Radio/>} label="I consent"/>
                <FormControlLabel value={false} control={<Radio/>} label="I do not consent"/>
            </RadioGroup>

            <p>
                to my special categories of personal data related to allergies and /or food intolerance being used for
                the purposes stated in point (iv) of the <a href={'/privacy.pdf'} target={'_blank'}
                                                            style={{textDecoration: 'underline', color: 'white'}}>Privacy
                Notice</a>.
            </p>
        </Container>
    )
}

export default PrivacyForm
