import React, {useState} from "react";
import Home from "../layouts/Home";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Box,
    CircularProgress,
    Snackbar, Stack,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import hotel from '../assets/data/hotel.json'
import {ExpandMore} from "@mui/icons-material";
import HotelCard from "../components/Accommodation/HotelCard";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Accommodation = () => {
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSent(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const handleCloseLoading =() => {
        setLoading(false)
    }

  return(
      <Home>
          <Box sx={{mb:10}}>
              <Snackbar open={sent} autoHideDuration={4000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                      Data successfully sent
                  </Alert>
              </Snackbar>
              <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseError}>
                  <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                      An error occurred, please try again
                  </Alert>
              </Snackbar>
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                  onClick={handleCloseLoading}
              >
                  <CircularProgress color="inherit" />
              </Backdrop>
              <Typography variant={matches? 'h3' : 'h5'}>Accommodation</Typography>
              <Typography variant={matches? 'h5' : 'subtitle1'} sx={{mt: 1}}>Please find below the list of our partner hotels where we have planned availabilities for Ferrari guests. Please get in contact through email to reserve your preferred accommodation specifying the Ferrari reservation code.</Typography>
              <Box style={{borderTop: '1px #ED1C24 solid', borderBottom: '1px #ED1C24 solid'}} sx={{py: 2, my:2}}>
                  {hotel.map(h => <HotelCard hotel={h} key={h.name}/>)}
              </Box>
          </Box>

      </Home>
  )
}

export default Accommodation
