import {createTheme} from "@mui/material";

export const ferrariTheme = createTheme({
    typography: {
        fontFamily: ['Ferrari Sans']
    },

    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    border: '1px solid #ffffff',
                    height: '2.5rem',
                    maxHeight: '2.5rem',
                    '&:hover': {
                        border: '1px solid #ffffff',
                        height: '2.5rem',
                        maxHeight: '2.5rem',
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: 'white',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Ferrari Sans'
                }
            },
            variants:[
                {
                    props:{variant:'change'},
                    style:{
                        background:'#050505',
                        color:"white",
                        '&:hover': {
                            background:'#121212',
                        }
                    }

                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: "linear-gradient(0deg, rgba(21,21,23,1) 0%, rgba(10,10,12,1) 79%);",
                    border: '0px',
                    borderBottom: '4px solid',
                    borderImage: 'linear-gradient(to right, rgb(237, 28, 35), rgb(0, 0, 0))',
                    borderImageSlice: '1'
                }
            }
        },
        MuiCard: {
            variants: [
                {
                    props:{variant:'activity'},
                    style:{
                        maxWidth:'900px',
                        borderRadius:'0 1rem 1rem 0',
                        borderLeft:'6px solid #ED1C24'
                    }
                },
                {
                    props: {variant: 'activity', selected:true},
                    style:{
                        borderColor:'white',
                        background:'rgba(237, 28, 35,0.7)'
                    }
                }
            ]
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#ED1C24',
        },
        /*
        secondary: {
            main: '#263238',
        },*/
        background: {
            default: '#050505',
        },
        /*accent: {
            main: '#e6fc69',
        },*/
        text: {
            primary: '#ffffff',
        }
    },
});
