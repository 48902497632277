import React from "react";
import {
    Box,
    capitalize,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup, Select, Stack, TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider, MobileTimePicker} from "@mui/x-date-pickers";
import MenuItem from "@mui/material/MenuItem";
import AirportStationSelect from "./AirportStationSelect";
import HotelSelect from "./HotelSelect";

const TransferCard = ({dato, handleChange, type}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box sx={{my: 3}}>
            <Typography variant={"h5"}>{capitalize(dato.id)}</Typography>
            <Box style={{borderTop: '1px #ED1C24 solid', borderBottom: '1px #ED1C24 solid'}} sx={{py: 2}}>
                <Box>
                    <Typography>From</Typography>
                    {type==='arrival' && <AirportStationSelect dato={dato} handleChange={handleChange} type={type}/>}
                    {type==="departure" && <HotelSelect dato={dato} handleChange={handleChange} type={type}/>}
                </Box>
                <Box>
                    <Typography>To</Typography>
                    {type==="departure" && <AirportStationSelect dato={dato} handleChange={handleChange} type={type}/>}
                    {type==='arrival' && <HotelSelect dato={dato} handleChange={handleChange} type={type}/>}
                </Box>
                <Stack direction={matches ? "row" : "column"} spacing={4}>
                    <Box>
                        <Typography>Date</Typography>

                        <FormControl fullWidth>
                            <Select
                                labelId="date"
                                id="date"
                                value={dato.date} sx={{minWidth:274}}
                                onChange={(event) => handleChange(event, dato.id, 'date')}
                            >
                                {type === 'arrival' && <MenuItem value={14}>14/06/2022</MenuItem>}
                                {type === 'arrival' && <MenuItem value={15}>15/06/2022</MenuItem>}
                                {type === 'departure' && <MenuItem value={16}>16/06/2022</MenuItem>}
                                {type === 'departure' && <MenuItem value={17}>17/06/2022</MenuItem>}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <Typography>Time</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileTimePicker
                                value={dato.time}
                                onChange={(event) => handleChange(event, dato.id, 'time')}
                                renderInput={(params) => <TextField {...params} className={'testo-time'} style={{minWidth:'100%'}} />}
                            />
                        </LocalizationProvider>
                    </Box>
                </Stack>
                <Box sx={{mt:2}}>
                    <Typography>Train/Flight number</Typography>
                    <TextField value={dato.number} onChange={(event) => handleChange(event, dato.id, 'number')}/>
                </Box>
            </Box>
        </Box>
    )
}

export default TransferCard
