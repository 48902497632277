import React, {useState} from "react";
import {
    Box, Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup, Typography,
} from "@mui/material";
import {use} from "i18next";
import SlotDialog from "./SlotDialog";


const ActivityCardContent = ({activity, selected, handlePartecipate, handleLunch, handlePreview}) => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        if(value!==''){
            handlePreview(value)
        }
    };

    const Content = () => {

        switch (activity.id) {
            case 'lunch':
                return (
                    <FormControl>
                        <Typography>Choose your lunch</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="lunch"
                            name="lunch"
                            value={selected}
                            onChange={handleLunch}
                        >
                            <FormControlLabel value="lunch" control={<Radio style={{color: "white"}}/>} label="Lunch"/>
                            <FormControlLabel value="box" control={<Radio style={{color: "white"}}/>}
                                              label="Box Lunch"/>
                        </RadioGroup>
                    </FormControl>
                )
            case 'preview':
                return (
                    <Box>
                        {selected!=='' && <Typography>Your time slot is: <b style={{textTransform:'uppercase'}}>{selected}</b></Typography>}
                        <Button style={{width:'100%'}} onClick={handleClickOpen} variant={selected==='' ? 'contained' : 'change'} sx={{color:'white'}}>
                            {selected==='' ? 'Choose time slot' : 'Change time slot'}
                        </Button>
                    </Box>
                )
            default:
                return (
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="gala dinner"
                            name="gala dinner"
                            value={selected}
                            onChange={(event) => handlePartecipate(event, activity.id)}
                        >
                            <FormControlLabel value={true} control={<Radio style={{color: "white"}}/>} label="I will attend"/>
                            <FormControlLabel value={false} control={<Radio style={{color: "white"}}/>} label="I will not attend"/>
                        </RadioGroup>
                    </FormControl>
                )

        }

    }

    return (
        <Box style={{minWidth: '42%'}}>
            <Content/>
            <SlotDialog open={open} handleClose={handleClose} handlePreview={handlePreview}/>
        </Box>
    )
}

export default ActivityCardContent
