import React from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const AirportStationSelect = ({dato, handleChange,type}) => {
  return(
      <FormControl>
        <RadioGroup
            aria-labelledby="arrival"
            name="arrival"
            value={type==='arrival' ? dato.from : dato.to}
            row
            onChange={(event) => handleChange(event, dato.id, type==='arrival' ? 'from' : 'to')}
        >
          <FormControlLabel value="bologna-airport" control={<Radio/>} label="Bologna Airport"/>
          <FormControlLabel value="bologna-station" control={<Radio/>} label="Bologna Train Station"/>
          <FormControlLabel value="modena-station" control={<Radio/>} label="Modena Train Station"/>
            <FormControlLabel value="reggio-station" control={<Radio/>} label="Reggio Emilia Train Station"/>
        </RadioGroup>
      </FormControl>
  )
}

export default AirportStationSelect
