import React, {useEffect, useState} from "react";
import Home from "../layouts/Home";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    TextareaAutosize,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import TransferCard from "../components/Transfer/TransferCard";
import axios from "axios";
import authHeader from "../services/auth-header";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transfer = () => {

    const [dati, setDati] = useState({
            arrival: {
                id: 'arrival',
                from: '',
                to: '',
                time: new Date(),
                date: '',
                number: ''
            },

            departure: {
                id: 'departure',
                from: '',
                to: '',
                time: new Date(),
                date: '',
                number: ''
            },
        }
    )
    const [note, setNote] = useState('')

    const handleChange = (event, id, key) => {
        setDati({...dati, [id]: {...dati[id], [key]: event.target ? event.target.value : event}})
    }
    useEffect(() => {
        compileData().then()
    }, [])

    const compileData = async () => {
        setLoading(true)
        await axios.get('https://cmd2022.ferrari.com/api/user/transfer', {headers: authHeader()})
            .then(r => {
                if (r.data !== '') {
                    setDati({...dati, arrival: r.data.arrival, departure: r.data.departure})
                    setNote(r.data.note)
                }
            })
        setLoading(false)
    }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));


    const handleNote = (event) => {
        setNote(event.target.value)
    }

    const handleSendData = async () => {
        setLoading(true)
        await postData()
        setLoading(false)
    }

    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)

    const postData = async () => {
        await axios.post('https://cmd2022.ferrari.com/api/user/updateTransfer', {
            data: {
                ...dati,
                note: note
            }
        }, {headers: authHeader()})
            .then(r => setSent(true))
            .catch(e => setError(true))
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSent(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const handleCloseLoading = () => {
        setLoading(false)
    }

    return (
        <Home>
            <Box sx={{mb: 10}}>
                <Snackbar open={sent} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Data successfully sent
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                        An error occurred, please try again
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Typography variant={matches ? 'h3' : 'h5'}>Transfer</Typography>
                <Typography variant={matches ? 'h5' : 'subtitle1'} sx={{mt: 1}}>
                    Transfers during the event will be available only for those staying in recommended accommodations.
                    Please fill in the form below for transfers to and from the airport or stations.
                </Typography>
                {
                    Object.keys(dati).map(d =>
                        <TransferCard key={d} type={d} dato={dati[d]} handleChange={handleChange}/>
                    )
                }
                <Typography variant={"h5"}>Special request</Typography>
                <Box style={{borderTop: '1px #ED1C24 solid', borderBottom: '1px #ED1C24 solid'}} sx={{py: 2}}>
                    <TextareaAutosize value={note} minRows={4} onChange={handleNote}
                                      style={{
                                          width: '100%',
                                          fontFamily: 'Ferrari Sans',
                                          background: "transparent",
                                          color: "white",
                                          resize: 'none',
                                          border: '1px solid white',
                                          borderRadius: '4px'
                                      }}/>
                </Box>
                <Box style={{textAlign: 'right'}} sx={{my: 3}}>
                    <Button variant={"contained"} onClick={handleSendData}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Home>
    )
}

export default Transfer
