import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    TextareaAutosize,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Home from "../layouts/Home";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import authHeader from "../services/auth-header";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Catering = () => {
    const [note, setNote] = useState('')
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        compileData().then()
    }, [])

    const compileData = async () => {
        setLoading(true)
        await axios.get('https://cmd2022.ferrari.com/api/user/catering', {headers: authHeader()})
            .then(r => {
                if (r.data !== '') {
                    setNote(r.data)
                }
            })
        setLoading(false)
    }

    const handleNote = (event) => {
        setNote(event.target.value)
    }

    const handleSendData = async () => {
        setLoading(true)
        await postData()
        setLoading(false)
    }

    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)

    const postData = async () => {
        await axios.post('https://cmd2022.ferrari.com/api/user/updateCatering', {data: note}, {headers: authHeader()})
            .then(r => setSent(true))
            .catch(e => setError(true))
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSent(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const handleCloseLoading = () => {
        setLoading(false)
    }

    return (
        <Home>
            <Box sx={{mb: 10}}>
                <Snackbar open={sent} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Data successfully sent
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                        An error occurred, please try again
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Typography variant={matches ? 'h3' : 'h5'}>Catering</Typography>
                <Typography variant={matches ? 'h5' : 'subtitle1'} sx={{mt: 1}}>Please indicate below your food allergy,
                    intolerance or dietary
                    requirements.
                </Typography>
                <Box style={{borderBottom: '1px #ED1C24 solid'}} sx={{py: 2}}>
                    <TextareaAutosize value={note} minRows={7} onChange={handleNote}
                                      style={{
                                          width: '100%',
                                          fontFamily: 'Ferrari Sans',
                                          background: "transparent",
                                          color: "white",
                                          resize: 'none',
                                          border: '1px solid white',
                                          borderRadius: '4px'
                                      }}/>
                </Box>
                <Box style={{textAlign: 'right'}} sx={{my: 3}}>
                    <Button variant={"contained"} onClick={handleSendData}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Home>
    )
}

export default Catering
